<template>
<div class="col-md-12">
  <div class="row">
    <div class="img-div"><img style="height: 40px" src="logo.jpg"/></div>
    <div class="title-div">
      <p class="title-text">ИНСТИТУТ ПРАКТИЧЕСКОЙ СОЦИАЛЬНОЙ</p>
      <p class="title-text">ПСИХОЛОГИИ АЛЕКСАНДРА ШАХОВА</p>
    </div>
  </div>
  <hr>
</div>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.img-div {
  display: inline-block;
  width: 40px;
}
.title-div {
  text-align: left;
  width: 90%;
  padding-left: 20px;
}
.title-text {
  font-size: 14px;
  margin-bottom: 0;
}
.row {
  margin-top: 12px;
}

@media (max-width: 769px) {
  .row {
    padding-left: 10px;
  }
  .title-div {
    padding-left: 10px;
    width: 88%;
  }
}
</style>
