<template>
<div class="container-fluid">
  <div id="app">
    <div class="row" id="mainRow">
      <div class="col-lg-9" id="mainContent">
        <Header />
        <Title />
        <Video />
        <!-- <PeopleOnline v-bind:peopleOline="peopleOnline"/> -->
        <Timer />
      </div>
      <div class="col-lg-3">
        <Form />
        <Comments />
      </div>
      <Preloader />
    </div>
  </div>
</div>
</template>

<script>
import Header from './components/Header.vue'
import Title from './components/Title.vue'
import Video from './components/Video.vue'
import Form from './components/Form.vue'
// import PeopleOnline from './components/PeopleOnline.vue'
import Comments from './components/Comments.vue'
import Timer from './components/Timer.vue'
import Preloader from './components/Preloader.vue'

import axios from 'axios'
import io from 'socket.io-client'
import { mapGetters, mapActions } from 'vuex'

const socket = io('https://socket.ashahov.ru', { 
  transports: ["websocket"], 
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax : 5000,
  reconnectionAttempts: Infinity 
});

export default {
  name: 'App',
  components: {
    Header,
    Title,
    Video,
    Form,
    // PeopleOnline,
    Timer,
    Comments,
    Preloader
  },
  data() {
    return {
      peopleOnline: 0,
      room: 'ipp_web_with_admin',
    }
  },
  computed: mapGetters(['webData']),
  created() {
    socket.emit('room', this.room);
    // socket.on('uses-online', (data) => {
    //   this.peopleOnline = data;
    // });
    socket.on('update-data', (data) => {
      console.log(data)
      this.fetchWebData()
      setTimeout(() => {
        if (this.webData.isRedirect && this.webData.redirectUrl != '') {
          window.location.href = this.webData.redirectUrl
        }
      }, 1000);
    });
    socket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          // the disconnection was initiated by the server, you need to reconnect manually
          socket.connect();
        }
    });

    this.logUserData(this.seachUserData())
    setInterval(() => {
			this.logUserData(this.seachUserData())
		}, 2 * 60 * 1000)
  },
  mounted() {
    this.fetchWebData()
    setTimeout(() => {
     if (this.webData.isRedirect && this.webData.redirectUrl != '') {
       window.location.href = this.webData.redirectUrl
     }
    }, 1000);
  },
  methods: {
    ...mapActions(['fetchWebData']),
    seachUserData(){
      let userData = {}
      let expectedData = ['email', 'leeloo_account_id', 'gr_id']
      let expectedUtms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']

      expectedData.forEach(element => {
        userData[element] = this.findDataInLocalStorageOrParams(element)
      })

      expectedUtms.forEach(utm => {
        userData[utm] = this.findUtms(utm)
      })

      return userData
    },
    findDataInLocalStorageOrParams(elem){
      if (localStorage.getItem(elem) !== "null" && localStorage.getItem(elem)) {
        return localStorage.getItem(elem)
      } else {
        let queryParam = this.$route.query[elem] ? this.$route.query[elem] : null
        if (queryParam) {
          localStorage.setItem(elem, queryParam)
        }
        return queryParam
      }
    },
    findUtms(utm){
      return this.$route.query[utm] ? this.$route.query[utm] : null
    },
    logUserData(data) {
      axios.post('log/handle.php', data)
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@media (max-width: 767.98px) {
  #mainContent {
    padding-left: 0;
    padding-right: 0;
  }
  .container-fluid {
    padding-right: 0;
  }
  .row {
    margin-right: 0;;
  }
}

.hc__ht {
  display: none !important;
}

</style>
