<template>
  <div class="col-md-12" id="videoCover">
    <div class="video-block">
      <iframe v-bind:src="'https://www.youtube.com/embed/' + webData.videoLink + '?rel=0&showinfo=0&autoplay=1&playsinline=1'" allow="autoplay; encrypted-media" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Video',
  props: {
    videoCode: String
  },
  computed: mapGetters(['webData']),
  methods: {
    scrollToElement() {
      const el = this.$el.getElementsByClassName('video-block')[0]
      if (el) {
        el.scrollIntoView({behavior: 'smooth', block: "start"})
      }
    }
  },
  mounted() {
    this.scrollToElement()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.video-block {
  width: 100%;
}
iframe {
  position: relative;
  height: 70vh;
  width: 100%;
}

@media (max-width: 767.98px) {

iframe {
  position: relative;
  height: 30vh;
  width: 100%;
}

#videoCover {
  padding-right: 0;
  padding-left: 0;
}

}

@media (min-width: 481px) and (max-width: 767px) {
  
  iframe {
    position: relative;
    min-height: 240px;
    width: 100%;
  }
  
}

</style>
