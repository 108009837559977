<template>
    <!-- <div id="hypercomments_widget"></div> -->
    <div id="mc-container"></div>
</template>

<script>
export default {
  name: 'Comments',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.hc__top {
  display: none;
}
#mc-container {
  margin-top: 20px !important;
}
.mc-auth-social {
  display: none !important;
}
</style>
