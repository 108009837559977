<template>
  <div v-if="isTimer || isMarquee" class="com-md-12">
    <div v-if="isMarquee" class="marquee"><span>{{ webData.marqueeText }}</span></div>
    <div v-if="isTimer" id="defaultCountdown">
      <Countdown v-bind:end="webData.timerFinish"></Countdown>
    </div>
  </div>
  <br v-else>
</template>

<script>
import Countdown from 'vuejs-countdown'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Timer',
  components: { 
    Countdown 
  },
  computed: mapGetters(['webData', 'isTimer', 'isMarquee']),
  mounted() {
    setInterval(() => {
      this.runTimerCheck(this.webData)
		}, 1 * 60 * 1000)
  },
  methods: {
    ...mapActions(['runTimerCheck'])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h5 {
  text-align: right;
  padding-right: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#defaultCountdown {
  font-size: 60px;
  color: #cb7a57;
}

/* marquee style */
.marquee{
  width:100%;
  white-space:nowrap;
  overflow:hidden;
  background: linear-gradient(-135deg, #cb7a57, #e3a990, #dfa389, #c58265);
  margin-bottom: 20px;
  margin-top: 20px;
}
 
.marquee span {
  color:white;
  font-size:25px;
  display:inline-block;
  padding-left:100%;
  -webkit-animation: marquee 40s infinite linear;
  animation: marquee 40s infinite linear;
}
 
@-webkit-keyframes marquee {
  0%{-webkit-transform: translate(0, 0);}
  100%{-webkit-transform: translate(-100%, 0);}
}
@keyframes marquee {
  0%{transform: translate(0, 0);}
  100%{transform: translate(-100%, 0)}
}

@media (max-width: 769px) {
  #defaultCountdown {
    font-size: 10px;
    color: #cb7a57;
  }
}

</style>

<style>
.text {
  display: none;
}
.digit {
  font-size: 50px !important;
}
</style>
