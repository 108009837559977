<template>
    <div v-if="isActiveForm" class="container">
        <div class="row">
          <div class="col-md-12 tabs">
            <span class="tab" v-bind:class="{ 'active-tab': formData.packageKey == 0 }" @click="clickFirstTab"><b>ФАКУЛЬТЕТ <br> "ПСИХОТЕРАПИЯ"</b></span>
            <span class="tab" v-bind:class="{ 'active-tab': formData.packageKey == 1 }" @click="clickSecondTab"><b>ФАКУЛЬТЕТ ПРОФЕССИЯ "ПСИХОЛОГ КОНСУЛЬТАНТ"</b></span>
          </div>
            <div class="inner col-md-12 formContent">
                <div class="formHeaderDiv">
                  <p v-if="formData.packageKey == 0" id="formTitle" v-html="webData.formTitle1"></p>
                  <p v-else id="formTitle" v-html="webData.formTitle2"></p>
                  <p v-if="webData.isCourseStartDate" id="formDesc">
                    <span class="roseBlock">{{ webData.courseStartDate }}</span>
                  </p>
                </div>
                <div>
                  <p id="oldPrice">{{ formData.oldPrice }} руб</p>
                  <p id="currentPrice">{{ formData.currentPrice }} руб</p>
                  <p v-if="formData.discount" id="discountPrice">выгода {{ formData.discount }} </p>
                </div>
                <div class="packages-select">
                    <label class="radio" for="1_full"><input type="radio" class="inpt-radio" id="1_full" name="radiogroup1" v-on:click="selectPackage('full')" checked>ПОЛНАЯ ОПЛАТА</label>
                    <label class="radio" for="1_prepay"><input type="radio" class="inpt-radio" id="1_prepay" name="radiogroup1" v-on:click="selectPackage('prepay')">РАССРОЧКА (24/12/10/6/3 месяцев) <!-- {{ formData.prepayPrice }} РУБ <br> <span style="margin-left: 20px; font-size: 10px;"> (Бронь вашего места на 2 потоке) </span> --></label>
                </div>
                <div>
                  <div class="programDesc">
                    <p v-for="option in options[formData.packageKey]" :key="option.key" class="courseItems" v-html="option.text"></p>
                  </div>
                </div>
                <form id="mainForm">
                    <input v-model="formData.name" id="name" type="text" name="name" placeholder="Введите ваше имя*" value="">
                    <br>
                    <input v-model="formData.email" id="email" type="email" name="email" placeholder="Введите ваш E-mail*">
                    <br>
                    <input v-model="formData.phone" type="tel" id="phone" name="phone" placeholder="Введите ваш телефон*" value="">
                    <br>
                    <input class="button" type="submit" @click.prevent="makeApply" value="Забронировать">
                </form>
                <div class="agreements">
                  <input type="checkbox" id="checkbox" v-model="checked_1" @change="legalChecked1">
                  <label id="checkbox_label" for="checkbox" v-bind:class="{ 'error-text': legalEmptyError_1 }">Я принимаю условия <a href="https://ippshahov.com/public/offer">публичной оферты</a>, <a href="https://ippshahov.com/public/privacy">политики конфиденциальности</a>, возврата.</label>
                </div>
                <div class="agreements">
                  <input type="checkbox" id="checkbox" v-model="checked_2" @change="legalChecked2">
                  <label id="checkbox_label" for="checkbox" v-bind:class="{ 'error-text': legalEmptyError_2 }">Я даю согласие на обработку персональных данных.</label>
                </div>
                <div class="agreements">
                  <input type="checkbox" id="checkbox" v-model="checked_3" @change="legalChecked3">
                  <label id="checkbox_label" for="checkbox" v-bind:class="{ 'error-text': legalEmptyError_3 }">Я ознакомлен с информацией о том, что диплом выданный на территории РФ даёт возможность работать онлайн по всему миру , но не признаётся действительным при приеме на работу, на территории Украины.</label>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="!isActiveForm && isFormSent && Date.now() > (new Date(webData.showFormTime).getTime())">
        <div class="showFormClass">
          <span class="showFormBtn" @click.prevent="showHidenForm">Показать форму</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Form',
  data(){
    return {
      checked_1: true,
      checked_2: true,
      checked_3: true,
      legalEmptyError_1: false,
      legalEmptyError_2: false,
      legalEmptyError_3: false,
      options: [
        [
          { text: '<b>2</b> года обучения'},
          { text: '<b>4 модуля:</b> 25 тем, 59 подтем, 118 занятий'},
          { text: '<b>Практические занятия:</b> 2 раза в неделю (вт и сб) <br><br>'},
          { text: '<b>Практика:</b> каждую неделю отработка полученных методик (вт−сб → работа в тройках в качестве клиента)'},
          { text: '<b>Дополнительная практика:</b> групповой практикум 1 раз в 2/3 недели после каждой пройденной темы'},
          { text: 'Методические материалы и сопровождающие пособия по каждой теме, которые разработаны нашим институтом'},
          { text: 'Обратная связь от кураторов-психологов и педагогов института'},
          { text: 'Закрытый чат нетворкинг эмоциональной поддержки'},
          { text: '<b>Сертификат</b> по окончании утвержденной формы института'},
        ], 
        [
          { text: '<b>2</b> года обучения'},
          { text: '<b>4 модуля:</b> 25 тем, 59 подтем, 236 занятий'},
          { text: '<b>Практические занятия:</b> 4 раза в неделю (вт и сб) <br><br>'},
          { text: '<b>Практика:</b> каждую неделю отработка полученных методик (вт−сб → работа в тройках в качестве психолога, наблюдателя и клиента)'},
          { text: '<b>Дополнительная практика:</b> групповой практикум 1 раз в 2/3 недели после каждой пройденной темы'},
          { text: 'Методические материалы и сопровождающие пособия по каждой теме, которые разработаны нашим институтом'},
          { text: 'Обратная связь от кураторов-психологов и педагогов института'},
          { text: 'Закрытый чат нетворкинг эмоциональной поддержки'},
          { text: '<b>Тестирование и экзамены:</b>'},
          { text: 'мини тесты по окончании пройденной темы (1 раз в 2/3 недели)'},
          { text: 'тестирование по окончании пройденного модуля (1 раз в 6 месяцев)'},
          { text: 'промежуточный срез по окончании первого года'},
          { text: 'итоговый экзамен по окончании двух лет обучения'},
          { text: '<b>Бонусы:</b>'},
          { text: 'Алгоритмы работы, инструкции, полезные в работе материалы'},
          { text: '<b>Итого:</b>'},
          { text: 'Дополнительные методики и инструкции по работе с ними'},
          { text: 'Углубленная практическая подготовка'},
          { text: 'Решения реальных кейсов с клиентами'},
          { text: 'Практические упражнения через образовательную геймификацию'},
          { text: '<b>Сертификат</b> по окончании утвержденной формы института'},
          { text: '<b>Диплом</b> о профессиональной переподготовке по специальности «Психолог-консультант» после успешной сдачи экзаменов'},
          { text: '<b>Возможность</b> пройти отбор и получить трудоустройство в команду и Центр психологической помощи (онлайн/оффлайн) Александра Шахова (в случае успешной аккредитации)'},
        ],
      ],
    }
  },
  mounted() {
    this.getGeo()
    setInterval(() => {
			this.runFormTimeCheck()
		}, 1 * 60 * 1000)
    console.log(this.formData.discount)
  },
  computed: mapGetters(['webData', 'formData', 'isActiveForm', 'isFormSent']),
  methods: {
    ...mapActions(['runFormTimeCheck', 'clickFirstTab', 'clickSecondTab', 'selectPackage', 'getGeo', 'setFormSent', 'setFormActive', 'setPreloader']),
    makeApply(){ 
      this.formData.utm_source = this.$route.query.utm_source ? this.$route.query.utm_source : '';
      this.formData.utm_campaign = this.$route.query.utm_campaign ? this.$route.query.utm_campaign : '';
      this.formData.utm_medium= this.$route.query.utm_medium ? this.$route.query.utm_medium : '';
      this.formData.utm_content = this.$route.query.utm_content ? this.$route.query.utm_content : '';
      this.formData.utm_term = this.$route.query.utm_term ? this.$route.query.utm_term : '';

      if (!this.checked_1) {
        this.legalEmptyError_1 = true;
      }
      if (!this.checked_2) {
        this.legalEmptyError_2 = true;
      }
      if (!this.checked_3) {
        this.legalEmptyError_3 = true;
      }

      if (this.formData.email && this.formData.phone && this.checked_1 && this.checked_2 && this.checked_3) {
      this.setPreloader(true)
      this.setFormSent(true)
      this.setFormActive(false)
      localStorage.setItem(this.formData.apply_config_id, true);
        axios.post('https://server.ashcourse.ru/api/apply', this.formData)
        .then(function (response) {
          window.location.href = response.data.redirect_link_with_params;
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },
    showHidenForm(){
      this.setFormSent(false)
      this.setFormActive(true)
    },
    legalChecked1(e){
      if (e.target.checked) {
        this.legalEmptyError_1 = false
      }
    },
    legalChecked2(e){
      if (e.target.checked) {
        this.legalEmptyError_2 = false
      }
    },
    legalChecked3(e){
      if (e.target.checked) {
        this.legalEmptyError_3 = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.packages-select label {
  margin-bottom: 5px;
}

.radio {
  color: #000;
  font-size: 16px;
  font-family: 'Roboto',Arial,sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-color: transparent;
  display: block;
  text-align: left;
  margin-left: 10px;
}

.inpt-radio {
  margin-right: 10px;
}

.error-text {
  color: red;
}

.agreements {
  display: flex;
  text-align: left;
  justify-content: flex-start;
}

#checkbox {
  vertical-align: top;
  margin: 2px 10px;
  flex: none;
}

#checkbox_label {
  font-size: 10px;
}

.formContent {
  background-color: #f0f8ff;
  box-shadow: 0 5px 7px rgb(0 0 0 / 50%);
  padding-bottom: 20px;
}

.tab {
    max-width: 49%;
    min-width: 49%;
    margin-right: 1%;
    /* border-top: 1px solid red;
    border-left: 1px solid red; */
    background:rgb(255 227 214);
    display: inline-block;
    color: rgba(136,14,79,1);
    font-size: 9px;
    padding: 10px;
}

.tab:hover {
  cursor: pointer;
}

.active-tab {
    /* background:#cc8e73; */
    background:linear-gradient(-135deg, #cb7a57, #e3a990, #dfa389, #c58265);
    color: white;
    box-shadow: 0px 14px 9px -12px rgb(0 0 0 / 40%);
}

.tabs {
  margin-top: 30px;
  padding-left: 0;
  padding-right: 0;
}

.courseItems {
  margin: 0;
}

#currentPrice {
  font-size: 26px;
  color: #f35611;
  font-weight: 500;
  margin-bottom: 0;
}

#oldPrice {
  font-size: 22px;
  color: black;
  font-weight: 500;
  margin-bottom: 0;
  text-decoration: line-through;
}

#discountPrice {
  font-size: 22px;
  color: #f35611;
  font-weight: 500;
  margin-bottom: 0;
}

.packages-select {
  margin-top: 10px;
}

.programDesc {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  margin-left: 10px;
}

.btn-outline-danger {
  width: 33%;
  border-radius: 0;
  border-bottom: 0;
  margin-right: 1px;
}

.roseBlock {
  background: #cc8e73;
  color: white;
  padding: 10px;
}

.showFormBtn {
  background: #cc8e73;
  color: white;
  padding: 10px;
  font-weight: 700;
  border-radius: 3px;
  cursor: pointer;
}

.showFormClass {
  text-align: center;
  margin-top: 20px;
}

#bonusDesc {
    border: 1px solid #cc8e73;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.button {
  display: inline-block;
  border: none;
  width: 100%;
  background:linear-gradient(-135deg, #cb7a57, #e3a990, #dfa389, #c58265);
  /* background: red; */
  color: #fff;
  text-transform: uppercase;
  margin-top: 20px;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  -webkit-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  margin-bottom: 30px;
}


#formTitle, #formDesc {
  color: #000000;
  font-size: 16px;
  font-family: 'Gilroy',Arial,sans-serif;
  line-height: 1.3;
  /* font-weight: 600; */
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  margin-top: 12px;
}

#formTitle {
  margin-top: 10px;
  margin-bottom: 5px;
}

.roseText {
  color: #cc8e73;
}

#mainForm {
  width: 90%;
  margin: 0 auto;
}

form input, #packageSelect {
  color: #000;
  background-color: white;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  height: 45px;
  margin: 0;
  margin-bottom: 10px;
  font-family: 'Gilroy',Arial,sans-serif;
  padding: 0 20px;
  line-height: 1.33;
  width: 100%;
  border: 0 none;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
}

form label {
    color: #000;
    font-weight: 500;
    font-family: 'Gilroy';
    font-size: 16px;
    padding-bottom: 5px;
    width: 100%;
    text-align: left;
}

.form-footer {
  margin-top: 15px;
}

.text {
  display: none !important; 
}

@media (max-width: 1100px) {
  #mainForm {
    width: 100%;
  }
}

@media (max-width: 1425px) {
  .tab {
    font-size: 9px;
  }
}

@media (max-width: 769px) {

  #mainForm {
    width: 90%;
  }

  #formTitle {
    font-size: 20px;
  }

  #formDesc {
    font-size: 18px;
  }

  .row {
    margin-right: -20px;
  }

  .programDesc {
    font-size: 13px;
  }

  .showFormClass {
    margin-top: 0;
    margin-bottom: 10px;
  }

}

</style>
