import axios from 'axios'
export default {
    actions: {
        async fetchWebData({commit}) {
            const res = await fetch('./static/data.json', {
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                 }
              })
            const webData = await res.json()

            commit('updateWebData', webData)
        },
        clickFirstTab({commit, state}) {
            state.formData.packageKey = 0
            state.formData.prepayPrice = state.webData.prepayPrice1
            state.formData.oldPrice = state.webData.priceProduct_1_Old
            state.formData.discount = state.webData.discountProduct_1
            state.formData.currentPrice = state.webData.priceProduct_1_Full
            if (state.formData.paymentType == 'prepay') {
                state.formData.apply_config_id = state.webData.configProduct_1_Prepay
            }
            if (state.formData.paymentType == 'full') {
                state.formData.apply_config_id = state.webData.configProduct_1_Full
            }
            commit('updateFormData', state.formData)
        },
        clickSecondTab({commit, state}) {
            state.formData.packageKey = 1
            state.formData.prepayPrice = state.webData.prepayPrice2
            state.formData.oldPrice = state.webData.priceProduct_2_Old
            state.formData.discount = state.webData.discountProduct_2
            state.formData.currentPrice = state.webData.priceProduct_2_Full
            if (state.formData.paymentType == 'prepay') {
                state.formData.apply_config_id = state.webData.configProduct_2_Prepay
            }
            if (state.formData.paymentType == 'full') {
                state.formData.apply_config_id = state.webData.configProduct_2_Full
            }
            commit('updateFormData', state.formData)
        },
        selectPackage({commit, state}, type) {
            state.formData.paymentType = type
            if (state.formData.packageKey == 0 ) {
                if (type == 'prepay') {
                    state.formData.apply_config_id = state.webData.configProduct_2_Prepay
                }
                if (type == 'full') {
                    state.formData.apply_config_id = state.webData.configProduct_1_Full
                }
            } else {
                if (type == 'prepay') {
                    state.formData.apply_config_id = state.webData.configProduct_2_Prepay
                }
                if (type == 'full') {
                    state.formData.apply_config_id = state.webData.configProduct_2_Full
                }
            }
            commit('updateFormData', state.formData)
        },
        getGeo({commit}){
            axios.get('https://api.2ip.ua/geo.json?key=dbebf45ff4c8f129')
            .then(response => {
              commit('updateGeoData', response)
            })
            .catch(error => {
              console.log(error);
            });
        },
        setFormSent({commit}, value = true) {
            commit('setFormSent', value)
        },
        setFormActive({commit}, value = true) {
            commit('setFormIsActive', value)
        },
        setPreloader({commit}, value = true) {
            commit('setPreloader', value)
        },
        runFormTimeCheck({commit}) {
            commit('updateFormDynamicData')
        },
        runTimerCheck({commit, state}) {
            commit('updateTimerData', state.webData)
        }
    },
    mutations: {
        updateWebData(state, webData) {
            state.webData = webData
        },
        updateFormData(state, formData) {
            state.formData = formData
        },
        updateFormDynamicData(state) {
            // проверка раз в минуту, активна ли форма (если заявка еще не была оправлена)
            if (!localStorage.getItem(state.formData.apply_config_id)) {
                state.formData.isActiveForm = Date.now() > (new Date(state.webData.showFormTime).getTime())
            }
        },
        updateGeoData(state, APIResponse) {
            state.formData.country = APIResponse.data.country_rus
            state.formData.city = APIResponse.data.city_rus
            state.formData.timezone = APIResponse.data.time_zone
        },
        setFormSent(state, value) {
            state.formData.isFormSent = value
        },
        setFormIsActive(state, value) {
            state.formData.isActiveForm = value
        },
        setPreloader(state, value) {
            state.formData.isPreloader = value
        },
        updateTimerData(state, webData) {
            state.timerData.isTimer = (Date.now() > (new Date(webData.timerStart).getTime()) && Date.now() < (new Date(webData.timerFinish).getTime()))
            state.timerData.isMarquee = (Date.now() > (new Date(webData.marqueeStart).getTime()) && Date.now() < (new Date(webData.marqueeFinish).getTime()))
        },
    },
    state: {
        webData: [],
        timerData: {
            isTimer: false,
            isMarquee: false,
        },
        formData: {
            oldPrice: '',
            currentPrice: '',
            discount: '',
            paymentType: 'full',
            apply_config_id: '',
            packageKey: 0,
            isActiveForm: false,
            isFormSent: false,
            isPreloader: false,
            country: '',
            city: '',
            timezone: ''
        }
    },
    getters: {
        webData(state) {
            return state.webData
        },
        formData(state) {
            if (state.formData.packageKey == 0 ) {
                state.formData.prepayPrice = state.webData.prepayPrice1
                state.formData.oldPrice = state.webData.priceProduct_1_Old
                state.formData.discount = state.webData.discountProduct_1
                state.formData.currentPrice = state.webData.priceProduct_1_Full
                if (state.formData.paymentType == 'prepay') {
                    state.formData.apply_config_id = state.webData.configProduct_1_Prepay
                }
                if (state.formData.paymentType == 'full') {
                    state.formData.apply_config_id = state.webData.configProduct_1_Full
                }
            } else {
                state.formData.prepayPrice = state.webData.prepayPrice2
                state.formData.oldPrice = state.webData.priceProduct_2_Old
                state.formData.discount = state.webData.discountProduct_2
                state.formData.currentPrice = state.webData.priceProduct_2_Full
                if (state.formData.paymentType == 'prepay') {
                    state.formData.apply_config_id = state.webData.configProduct_2_Prepay
                }
                if (state.formData.paymentType == 'full') {
                    state.formData.apply_config_id = state.webData.configProduct_2_Full
                }
            }
            return state.formData
        },
        isActiveForm(state) {
            // если пользователь уже оставлял заявку на данный продукт + проверка во избежание не инициализированного значения apply_config_id
            if (!localStorage.getItem(state.formData.apply_config_id) && state.formData.apply_config_id != '' && state.formData.apply_config_id != 'undefined') {
                state.formData.isActiveForm = Date.now() > (new Date(state.webData.showFormTime).getTime())
            }
            return state.formData.isActiveForm
        },
        isFormSent(state) {
            state.formData.isFormSent = localStorage.getItem(state.formData.apply_config_id) == 'true'
            return state.formData.isFormSent
        },
        isTimer(state) {
            state.timerData.isTimer = (Date.now() > (new Date(state.webData.timerStart).getTime()) && Date.now() < (new Date(state.webData.timerFinish).getTime()))
            return state.timerData.isTimer
        },
        isMarquee(state) {
            state.timerData.isMarquee = (Date.now() > (new Date(state.webData.marqueeStart).getTime()) && Date.now() < (new Date(state.webData.marqueeFinish).getTime()))
            return state.timerData.isMarquee
        }
    },
}