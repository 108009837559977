import Vue from 'vue'
import store from './store'
import App from './App.vue'
import VueRouter from 'vue-router'

Vue.config.productionTip = false
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', component: App },
  ]
});

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
