<template>
  <div class="row">
    <div v-if="webData.titleTime2 != ''" class="col-md-12 dates-box">
        <div class="col-md-6 dates">
          <p class="date-p"><b class="day-b"> День 1:</b>{{ webData.titleTime1 }}</p>
          <p class="title-p" v-html="webData.titleText1"> </p>
        </div>
        <hr class="date-hr">
        <div class="col-md-6 dates">
          <p class="date-p"><b class="day-b">День 2:</b>{{ webData.titleTime2 }}</p>
          <p class="title-p" v-html="webData.titleText2"> </p>
        </div>
    </div>
    <div v-else class="col-md-12 dates">
        <p class="date-p"><b class="day-b"></b>{{ webData.titleTime1 }}</p>
        <p v-html="webData.titleText1"></p>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Title',
  computed: mapGetters(['webData']),
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h4 {
    line-height: 1.3;
  }

  .dates {
    text-align: center;
  }

  .date-p {
    margin-bottom: 5px;
  }

  .day-b {
    margin-right: 5px;
    font-size: 20px;
  }

  .date-hr{
    width: 90%;
  }

  .dates-box {
    display: flex;
  }

  @media (max-width: 767.98px) {
  #mainTitleText {
    font-size: 16px;
  }
  .dates-box {
    display: block;
  }
  .dates {
    text-align: left;
    margin-left: 10px;
  }
  .date-p {
    font-size: 13px;
  }
  .day-b {
    font-size: 16px;
  }
  .title-p {
    font-size: 13px;
  }
}
</style>
